import { SvgIcon } from '@mui/material';

const ViewerIcon = ({ className = '', size = 24, color = 'currentColor', ...rest }) => (
  <SvgIcon
    viewBox='0 0 24 24'
    sx={{ width: size, height: size, fontSize: `${size}px`, fill: color }}
    className={className}
    {...rest}
  >
    <path
      d='M7.984 16.644C7.448 16.644 6.952 16.536 6.496 16.32C6.048 16.104 5.688 15.792 5.416 15.384C5.144 14.968 5.004 14.46 4.996 13.86H6.052C6.06 14.236 6.148 14.564 6.316 14.844C6.492 15.124 6.724 15.344 7.012 15.504C7.3 15.656 7.624 15.732 7.984 15.732C8.544 15.732 8.996 15.588 9.34 15.3C9.684 15.004 9.856 14.624 9.856 14.16C9.856 13.808 9.756 13.516 9.556 13.284C9.364 13.044 9.104 12.864 8.776 12.744C8.448 12.624 8.084 12.564 7.684 12.564H7.228V11.7H7.72C8.088 11.7 8.4 11.632 8.656 11.496C8.912 11.36 9.108 11.18 9.244 10.956C9.388 10.724 9.46 10.476 9.46 10.212C9.46 9.812 9.32 9.492 9.04 9.252C8.76 9.004 8.4 8.88 7.96 8.88C7.44 8.88 7.008 9.036 6.664 9.348C6.328 9.652 6.144 10.1 6.112 10.692H5.068C5.1 10.084 5.252 9.58 5.524 9.18C5.804 8.772 6.156 8.472 6.58 8.28C7.012 8.08 7.476 7.98 7.972 7.98C8.484 7.98 8.932 8.072 9.316 8.256C9.7 8.432 10 8.68 10.216 9C10.44 9.32 10.552 9.696 10.552 10.128C10.552 10.424 10.484 10.708 10.348 10.98C10.212 11.252 10.028 11.484 9.796 11.676C9.572 11.86 9.308 11.988 9.004 12.06C9.372 12.116 9.7 12.24 9.988 12.432C10.284 12.616 10.516 12.856 10.684 13.152C10.852 13.448 10.936 13.788 10.936 14.172C10.936 14.652 10.812 15.08 10.564 15.456C10.324 15.832 9.984 16.124 9.544 16.332C9.104 16.54 8.584 16.644 7.984 16.644ZM12.6807 16.5V8.124H15.1887C15.7887 8.124 16.3287 8.224 16.8087 8.424C17.2967 8.616 17.7127 8.896 18.0567 9.264C18.4007 9.624 18.6647 10.06 18.8487 10.572C19.0327 11.084 19.1247 11.656 19.1247 12.288C19.1247 12.928 19.0327 13.508 18.8487 14.028C18.6647 14.54 18.4007 14.984 18.0567 15.36C17.7127 15.728 17.2967 16.012 16.8087 16.212C16.3287 16.404 15.7887 16.5 15.1887 16.5H12.6807ZM13.7247 15.6H15.1887C15.7647 15.6 16.2647 15.464 16.6887 15.192C17.1207 14.92 17.4487 14.536 17.6727 14.04C17.9047 13.536 18.0207 12.952 18.0207 12.288C18.0207 11.624 17.9047 11.048 17.6727 10.56C17.4487 10.072 17.1207 9.696 16.6887 9.432C16.2647 9.16 15.7647 9.024 15.1887 9.024H13.7247V15.6Z'
      fill={color}
    />
  </SvgIcon>
);

export default ViewerIcon;
